import { PotfolioPageImg } from '../../assets/images'
import { TitleLetter } from '../../components/TitleLetter/TitleLetter'
import { ProjectCards } from '../../components/ProjectCards/ProjectCards'
import { Link } from 'react-router-dom'
import './PortfolioPage.scss'
import { PortfolioData } from './PortfolioData'

// swiper

import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative, Pagination } from 'swiper/modules'

const TitleLetterPortfolioPage = {
  labelNumber: '02',
  label: 'PORTFOLIO',
  title: 'ALL WORKS ',
}

export const PortfolioPage = () => {
  return (
    <section className="PortfolioPage">
      <div className="PortfolioPage-container">
        <div className="PortfolioPage-topPart">
          <TitleLetter
            label={TitleLetterPortfolioPage.label}
            labelNumber={TitleLetterPortfolioPage.labelNumber}
            title={TitleLetterPortfolioPage.title}
          />
        </div>
        <div className="PortfolioPage-desktop">
          <div className="PortfolioPage-desktop--slider-container">
            {PortfolioData.map((project) => (
              <Link
                key={project.id}
                className="Project-card--link"
                to={`/project/${project.id}`}
              >
                <ProjectCards
                  ProjectCardimage={project.imgProject}
                  ProjectCardlabel={project.labelProject}
                  ProjectCardtitle={project.nameProject}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="PortfolioPage-desktop-mobile">
        <div className="PortfolioPage-slider-mobile-container">
          <Swiper
            style={{
              '--swiper-pagination-bullet-size': '12px',
            }}
            className="SwiperPortfolioPage"
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: ['-120%', 0, -500],
              },
              next: {
                shadow: false,
                translate: ['120%', 0, -500],
              },
            }}
            pagination={true}
            modules={[Pagination, EffectCreative]}
          >
            {PortfolioData.map((project) => (
              <SwiperSlide key={project.nameProject}>
                <div className="PortfolioPage-slider-mobile">
                  <Link
                    className="Project-card--link"
                    to={`/project/${project.id}`}
                  >
                    <ProjectCards
                      ProjectCardimage={project.imgProject}
                      ProjectCardlabel={project.labelProject}
                      ProjectCardtitle={project.nameProject}
                    />
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <img
        className="PortfolioPageImg"
        src={PotfolioPageImg}
        alt="PotfolioPageImg"
      />
    </section>
  )
}
