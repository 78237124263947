import { useEffect, useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BurgerImg,
  HeaderCloseBtnImg,
  NavInstagram,
  NavLinkIn,
  LogoSVG,
  NavFacebook,
} from '../../assets/images'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './header.scss'
import { FitBackForm } from '../Form/FitBackForm'

const LangOptions = [
  { value: 'en', content: 'EN' },
  { value: 'ru', content: 'RU' },
  { value: 'ro', content: 'RO' },
]

export const Header = () => {
  const [t, i18n] = useTranslation('global')
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'ru',
  )
  const { pathname } = useLocation()
  const navigate = useNavigate()
  // const handleLogoClick = (e) => {
  //   e.preventDefault()
  //   window.location.reload()
  // }
  const onChangeLang = useCallback(
    (value) => {
      i18n.changeLanguage(value)
    },
    [i18n],
  )
  const onChangeHandler = (e) => {
    const newLanguage = e.target.value
    onChangeLang(newLanguage)
    setSelectedLanguage(newLanguage)
    localStorage.setItem('selectedLanguage', newLanguage)
  }

  const handleLogoClick = () => {
    window.scrollTo(0, 0)
  }

  const [navbar, setNavbar] = useState(false)
  const changeBackground = () => {
    if (window.pageYOffset > window.innerHeight * 0.05) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const navRef = useRef(null)

  const showNavbar = () => {
    const node = navRef.current
    node?.classList.toggle('navigation-responsive')
    if (window.matchMedia('(max-width: 1140px)').matches) {
      if (node?.classList.contains('navigation-responsive')) {
        document.getElementsByClassName('lang-select')[0].style.color = 'black'
        document.getElementsByClassName('multiLang-svg')[0].style.stroke =
          'black'
      } else {
        document.getElementsByClassName('lang-select')[0].style.color =
          '#DADADA'
        document.getElementsByClassName('multiLang-svg')[0].style.stroke =
          '#DADADA'
      }
    }
  }

  const NavLinks2 = [
    {
      index: '01',
      name: t('header.sevicesNav'),
    },
    {
      index: '02',
      name: t('header.PORTFOLIONav'),
    },
    {
      index: '03',
      name: t('header.OURTEAMNav'),
    },
    {
      index: '04',
      name: t('header.ABOUTUSNav'),
    },
    {
      index: '05',
      name: t('header.CONTACTSNav'),
    },
  ]

  const renderNavLink = (content, name) => {
    const scrollToId = `${content}Section`

    const handleClickNav = async () => {
      if (pathname === '/') {
        document
          .getElementById(scrollToId)
          .scrollIntoView({ behavior: 'smooth' })
      } else {
        await navigate('/')
        document
          .getElementById(scrollToId)
          .scrollIntoView({ behavior: 'smooth' })
      }
    }

    return (
      <button
        key={content}
        onClick={() => {
          handleClickNav()
          showNavbar()
        }}
        className="header-navigation--link"
      >
        <span>{content}</span>
        {name}
      </button>
    )
  }
  // const handleClickNav = () => {
  //     const scrollToId = `${}`
  //     document.getElementById()
  // }

  const formRef = useRef(null)
  const showFitBackForm = () => {
    const node = formRef.current
    node?.classList.toggle('Form-show')
  }

  const [isOpen, setOpen] = useState(false)

  const langRef = useRef(null)

  useEffect(() => {
    if (!isOpen) return

    const handleClick = (e) => {
      if (!langRef.current) return
      if (!langRef.current.contains(e.target)) {
        setOpen()
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.addEventListener('click', handleClick)
    }
  }, [isOpen])

  return (
    <header className={navbar ? 'header header_black' : 'header'}>
      <Link onClick={handleLogoClick} to="/">
        <img className="header-logo" src={LogoSVG} alt="Logo" />
      </Link>
      <nav className="navigation" ref={navRef}>
        {NavLinks2.map((nav) => renderNavLink(nav.index, nav.name))}
        <button
          className="HeaderForm_button"
          onClick={showFitBackForm}
          type="button"
        >
          {t('header.formButton')}
        </button>
        <div className="navigation-socNetwork-links">
          <a
            className="ContactsSection-bottom-rightPart--links"
            href="https://www.linkedin.com/company/sintech-corp/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NavLinkIn} alt="" />
          </a>
          <a
            className="ContactsSection-bottom-rightPart--links"
            href="https://www.instagram.com/sintech.corporation/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NavInstagram} alt="" />
          </a>
          <a
            className="ContactsSection-bottom-rightPart--links"
            href="https://www.facebook.com/sintech.corporation"
            target="_blank"
            rel="noreferrer"
          >
            <img src={NavFacebook} alt="" />
          </a>
        </div>
        <button
          className="navigation-btn navigation-close-btn"
          onClick={showNavbar}
          type="button"
        >
          <img src={HeaderCloseBtnImg} alt="" />
        </button>
      </nav>
      <div
        ref={langRef}
        className={isOpen ? 'lang-select lang-select--active' : 'lang-select'}
        onClick={() => setOpen(!isOpen)}
      >
        {selectedLanguage}
        <svg
          width="15"
          height="15"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="multiLang-svg"
            d="M5 9L8 6L5 3"
            stroke="white"
            strokeLinecap="round"
          />
        </svg>
        <div className={`select-menu ${isOpen ? 'select-menu--active' : ''}`}>
          <ul>
            {LangOptions.map(({ value, content }) => (
              <li key={value} className="lang-item">
                <button
                  className="lang-item--button"
                  type="button"
                  onClick={onChangeHandler}
                  value={value}
                >
                  {content}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <FitBackForm formRef={formRef} showFitBackForm={showFitBackForm} />
      <button className="navigation-btn" onClick={showNavbar} type="button">
        <img src={BurgerImg} alt="" />
      </button>
    </header>
  )
}
