import './footer.scss'
import { LogoSVG } from '../../assets/images'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export const Footer = () => {
  const [t] = useTranslation('global')

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-content--text">
          <h3>{t('footer.title1')}</h3>
          <h3>{t('footer.title2')}</h3>
          <h3>{t('footer.title3')}</h3>
        </div>
        <Link to="/">
          <img className="header-logo" src={LogoSVG} alt="Logo" />
        </Link>
      </div>
    </footer>
  )
}
