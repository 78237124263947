import { useState } from 'react'
import { AccordionItem } from './AccordionItem'
import './Accordion.scss'
import {
  vueImg,
  pythonImg,
  mySQLImg,
  nextJsImg,
  djangoImg,
  javaImg,
  nodeJsIMg,
  ReactImg,
} from '../../assets/images'
import { useTranslation } from 'react-i18next'

export const Accordion = ({ AccordionContent }) => {
  const [t] = useTranslation('global')
  const [openId, setId] = useState(null)

  return (
    <div className="accordion-wrapper">
      <ul className="accordion">
        {AccordionContent.map((AccordionContentItem, id) => {
          return (
            <AccordionItem
              onClick={() => (id === openId ? setId(null) : setId(id))}
              AccordionContentItem={AccordionContentItem}
              isOpen={id === openId}
              key={id}
            />
          )
        })}
      </ul>
      <div className="accordion-static-part">
        <span>{t('connects.accordion.bottomPart')}</span>
        <div className="accordion-static-part-images">
          <img src={ReactImg} alt="react" />
          <img src={vueImg} alt="react" />
          <img src={pythonImg} alt="react" />
          <img src={mySQLImg} alt="react" />
          <img src={nextJsImg} alt="react" />
          <img src={djangoImg} alt="react" />
          <img src={javaImg} alt="react" />
          <img src={nodeJsIMg} alt="react" />
        </div>
      </div>
    </div>
  )
}
