import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

import { useEffect, useState } from 'react'
//
const DEFAULT_PARTICLES_NUMBER = 15
//
export const Particle = () => {
  const [, setWindowWidth] = useState(window.innerWidth)
  const [particlesNumber, setParticlesNumber] = useState(
    DEFAULT_PARTICLES_NUMBER,
  )
  const particlesInit = async (main) => {
    await loadFull(main)
  }
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
      if (window.innerWidth <= 992) {
        setParticlesNumber(8)
      } else {
        setParticlesNumber(DEFAULT_PARTICLES_NUMBER)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        fullScreen: {
          enable: true,
          zIndex: -1,
        },
        style: {
          position: 'absolute',
        },
        particles: {
          number: {
            value: particlesNumber,
            density: {
              enable: false,
              value_area: 800,
            },
          },
          color: {
            value: '#fff',
          },
          shape: {
            type: '',
            options: {
              sides: 5,
            },
          },
          opacity: {
            value: 0.8,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 4,
            random: false,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          rotate: {
            value: 0,
            random: true,
            direction: 'clockwise',
            animation: {
              enable: true,
              speed: 5,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 1000,
            color: '#A11B1B',
            opacity: 1,
            width: 1,
          },
          move: {
            enable: true,
            speed: 1.5,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'bounce',
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: ['grab'],
            },
            onclick: {
              enable: false,
              mode: 'bubble',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }}
    />
  )
}
