export const SliderAboutUsContent = ({ themeLabel, theme, number, text }) => {
  return (
    <div className="AboutUs-Section-SliderContainer">
      <h2 className="AboutUs-Slider-Title">
        <span className="Tabs-Label">{themeLabel}</span>
        {theme}
      </h2>
      <h3>{number}</h3>
      <p>{text}</p>
    </div>
  )
}
