import './TitleLetter.scss'

export const TitleLetter = ({ text, title, label, labelNumber }) => {
  return (
    <div className="titleLetter-container">
      <div className="titleLetter-text">
        <h3>
          <span>{labelNumber}</span>
          {label}
        </h3>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  )
}
