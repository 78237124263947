import { Accordion } from '../../../../components/Accordion/Accordion'
import { LogoLetter, TitleLetterBackground } from '../../../../assets/images'
import { TitleLetter } from '../../../../components/TitleLetter/TitleLetter'
import './ConnectsSecton.scss'
import { useTranslation } from 'react-i18next'

export const ConnectsSection = () => {
  const [t] = useTranslation('global')

  const TitleLetter1 = {
    labelNumber: '01',
    label: t('connects.label'),
    text: t('connects.text'),
    title: t('connects.title'),
  }

  const AccordionContent = [
    {
      AccTitle: 'ERP/CRM DEVELOPMENT',
      AccText: 'ERP SYSTEM',
      AccText1: 'CRM SYSTEM',
      AccText2: t('connects.accordion.ERPtext3'),
    },
    {
      AccTitle: 'WEB DEVELOPMENT',
      AccText: ' Online store',
      AccText1: 'Social network',
      AccText2: 'Corporate website',
      AccText3: 'Forum',
      AccText4: 'Website for online learning',
      AccText5: 'Landing',
    },
    {
      AccTitle: 'DESIGN',
      AccText: 'Graphic Design',
      AccText1: 'Branding',
      AccText2: 'UI/UX DESIGN',
      AccText3: 'Custom Web-Design',
      AccText4: '3d Modeling, Animations',
      AccText5: 'Motion Design',
    },
    {
      AccTitle: 'APPLICATION DEVELOPMENT',
      AccText: 'Mobile app for Android',
      AccText1: 'MOBILE APP FOR IOS',
      AccText2: 'Second platform app development',
      AccText3: 'Maintenance and post-warranty support',
      AccText4: 'Mobile Web App Development',
      AccText5: 'Hybrid web applications',
    },
  ]

  return (
    <div id="01Section" className="ConnectsSection-container">
      <div className="ConnectsSection-topPart">
        <TitleLetter
          title={TitleLetter1.title}
          label={TitleLetter1.label}
          image={TitleLetter1.image}
          labelNumber={TitleLetter1.labelNumber}
          text={TitleLetter1.text}
        />
        <div className="LogoLetterContainer">
          <img className="LogoLetter" src={LogoLetter} alt="" />
        </div>
        <img
          className="TitleLetterBackground"
          src={TitleLetterBackground}
          alt=""
        />
      </div>
      <div className="ConnectsSection-AccordionContainer">
        <Accordion AccordionContent={AccordionContent} />
      </div>
    </div>
  )
}
