import './СollaboratorItem.scss'
import { useTranslation } from 'react-i18next'

export const CollaboratorItem = ({ name, position, description }) => {
  const [t] = useTranslation('global')

  return (
    <div className="CollaboratorItem-content">
      <h2 className="CollaboratorItem-name">{t(name)}</h2>
      <h3 className="CollaboratorItem-position">{position}</h3>
      <p className="CollaboratorItem-description">{t(description)}</p>
    </div>
  )
}
