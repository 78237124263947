import { Route, Routes, useLocation } from 'react-router-dom'
import { MainPage } from '../MainPage/MainPage'
import { Header } from '../../components/Header/header'
import { Footer } from '../../components/Footer/footer'
import { PortfolioPage } from '../PortfolioPage/PortfolioPage'
import { useEffect } from 'react'
import { ProjectPage } from '../ProjectPage/ProjectPage'

export const Router = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/project/:id" element={<ProjectPage />} />
      </Routes>
      <Footer />
    </>
  )
}
