import { ProtonImage, DemirImage } from '../../assets/images'

export const PortfolioData = [
  {
    nameProject: 'PROTON ENERGY',
    labelProject: 'portfolio.projectCardLabel',
    imgProject: ProtonImage,
    id: '01',
  },
  {
    nameProject: 'DEMIR AGRO',
    labelProject: 'portfolio.projectCardLabel',
    imgProject: DemirImage,
    id: '02',
  },
]
