import './InscriptionSection.scss'
import { useRef } from 'react'
import { FitBackForm } from '../../../../components/Form/FitBackForm'
import { useTranslation } from 'react-i18next'

export const InscriptionSection = () => {
  const [t] = useTranslation('global')
  const formRef = useRef(null)
  const showFitBackForm = () => {
    const node = formRef.current
    node?.classList.toggle('Form-show')
  }

  return (
    <section className="InscriptionSection">
      <FitBackForm formRef={formRef} showFitBackForm={showFitBackForm} />{' '}
      <div>
        <button onClick={showFitBackForm} type="button" className="Inscription">
          {t('inscription.formButton01')} <br /> {t('inscription.formButton02')}
        </button>
      </div>
    </section>
  )
}
