import { ContactsSection } from './screens/ContactsSection/ContactsSection'
import { ChooseSection } from './screens/ChooseSection/ChooseSection'
import { OurTeamSection } from './screens/OurTeamSection/OurTeamSection'
import { MainPageBanner } from './screens/MainPageBanner/MainPageBanner'
import { ConnectsSection } from './screens/ConnectsSection/ConnectsSection'
import { PortfolioSection } from './screens/PortfolioSection/PortfolioSection'
import './MainPage.scss'
import {InscriptionSection} from "./screens/InscriptionSection/InscriptionSection";

export const MainPage = () => {
  return (
    <>
      <MainPageBanner />
      <ConnectsSection />
      <PortfolioSection />
      <OurTeamSection />
      <ChooseSection />
      <ContactsSection />
        <InscriptionSection />
    </>
  )
}
