import './ProjectCards.scss'
import { useTranslation } from 'react-i18next'

export const ProjectCards = ({
  ProjectCardtitle,
  ProjectCardlabel,
  ProjectCardimage,
}) => {
  const [t] = useTranslation('global')

  return (
    <div className="ProjectCard">
      <img className="ProjectCard-image" src={ProjectCardimage} alt="" />
      <h2 className="ProjectCard-title">{ProjectCardtitle}</h2>
      <h3 className="ProjectCard-label">{t(ProjectCardlabel)}</h3>
    </div>
  )
}
