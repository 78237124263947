export const OurTeamData = [
  {
    name: 'ourTeam.slider.nameN',
    position: 'CEO in SINTECH',
    description: 'ourTeam.slider.descriptionN',
  },
  {
    name: 'ourTeam.slider.nameV',
    position: 'Project Manager',
    description: 'ourTeam.slider.descriptionV',
  },
  {
    name: 'ourTeam.slider.nameCV',
    position: 'Full-Stack  Developer',
    description: 'ourTeam.slider.descriptionCV',
  },
  {
    name: 'ourTeam.slider.nameAG',
    position: 'Front-End developer',
    description: 'ourTeam.slider.descriptionAG',
  },
  {
    name: 'ourTeam.slider.nameMA',
    position: 'UI/UX Designer / Graphic Designer',
    description: 'ourTeam.slider.descriptionMA',
  },
  {
    name: 'ourTeam.slider.nameAF',
    position: 'Front-End developer',
    description: 'ourTeam.slider.descriptionAF',
  },
  {
    name: 'ourTeam.slider.nameID',
    position: 'Full-Stack Developer',
    description: 'ourTeam.slider.descriptionID',
  },
  {
    name: 'ourTeam.slider.nameBE',
    position: 'Back-End Developer, DevOps Engineer',
    description: 'ourTeam.slider.descriptionBE',
  },
  {
    name: 'ourTeam.slider.nameRB',
    position: 'Front-End developer',
    description: 'ourTeam.slider.descriptionRB',
  },
]
