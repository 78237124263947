import './ProjectPage.scss'
import { TitleLetter } from '../../components/TitleLetter/TitleLetter'
import { PotfolioPageImg, ProtonImage, DemirImage } from '../../assets/images'
import { ProjectDescription } from '../../components/ProjectDescription/ProjectDescription'
import { Link, useParams } from 'react-router-dom'
// import { PortfolioData } from '../PortfolioPage/PortfolioData'
import { useTranslation } from 'react-i18next'

export const ProjectPage = () => {
  const params = useParams()
  const { id } = params
  const [t] = useTranslation('global')

  const TitleLetterProjectPage = {
    labelNumber: '02',
    label: t('portfolio.label'),
    title: 'PROTON ENERGY',
  }

  const PortfolioData = [
    {
      nameProject: 'PROTON ENERGY',
      labelProject: 'portfolio.projectCardLabel',
      imgProject: ProtonImage,
      id: '01',
      title: 'PROTON ENERGY',
      link: 'https://protonenergy.md/',
      number: 'Q3 2023',
      texts: [
        t('project.ProtonText1'),
        t('project.ProtonText2'),
        t('project.ProtonText3'),
        t('project.ProtonText4'),
      ],
    },
    {
      nameProject: 'DEMIR AGRO',
      labelProject: 'portfolio.projectCardLabel',
      imgProject: DemirImage,
      id: '02',
      title: 'DEMIR AGRO',
      link: 'https://demiragro.md/',
      number: 'Q3 2023',
      texts: [
        t('project.DemirText1'),
        t('project.DemirText2'),
        t('project.DemirText3'),
        t('project.DemirText4'),
      ],
    },
  ]

  const singleProject = PortfolioData.find((project) => project.id === id)

  return (
    <section className="ProjectPage-section">
      <div className="ProjectPage-container">
        {singleProject && (
          <>
            <div className="ProjectPage-topPart">
              <Link className="ProjectPage-BackButton" to="/portfolio">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.292893 6.79289C-0.0976315 7.18342 -0.0976314 7.81658 0.292893 8.20711L6.65686 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41421 7.5L8.07107 1.84315C8.46159 1.45262 8.46159 0.819457 8.07107 0.428933C7.68054 0.0384084 7.04738 0.0384085 6.65685 0.428933L0.292893 6.79289ZM15 6.5L1 6.5L1 8.5L15 8.5L15 6.5Z"
                    fill="white"
                  />
                </svg>
                {t('project.backButton')}
              </Link>
              <TitleLetter
                label={TitleLetterProjectPage.label}
                labelNumber={singleProject.id}
                title={singleProject.nameProject}
              />
            </div>
            <div className="ProjectPage-AboutProject">
              <div className="ProjectPage-AboutProject--sliderContainer">
                <div className="ProjectPageImage-container">
                  <img
                    className="ProjectPageImage"
                    src={singleProject.imgProject}
                    alt=""
                  />
                </div>
              </div>
              <ProjectDescription
                link={singleProject.link}
                texts={singleProject.texts}
                title={singleProject.title}
                number={singleProject.number}
              />
            </div>
          </>
        )}
      </div>
      <img
        className="PortfolioPageImg"
        src={PotfolioPageImg}
        alt="PotfolioPageImg"
      />
    </section>
  )
}
