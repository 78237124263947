import './ProjectDescription.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ProjectDescription = ({ title, number, texts, link }) => {
  const [t] = useTranslation('global')
  const [showText, setShowText] = useState(true)
  return (
    <div className="ProjectDescription">
      <div className="ProjectDescription-container">
        <div className="ProjectDescription-titleContainer">
          <h1 className="ProjectDescription-title">{title}</h1>
          <p className="ProjectDescription-number">{number}</p>
        </div>
        <a
          className="ProjectDescription-linkToProject"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {t('project.webLink')}
        </a>
        <div className="ProjectDescription-line"></div>
        <div
          className={
            showText
              ? 'ProjectDescription-text ProjectDescription-text_hidden'
              : 'ProjectDescription-text'
          }
        >
          {texts?.map((text) => (
            <p key={text}>
              {text} <br /> <br />{' '}
            </p>
          ))}
        </div>
        <button
          onClick={() => setShowText(!showText)}
          className="ProjectDescription-button"
        >
          {showText
            ? t('project.description.buttonMore')
            : t('project.description.buttonLess')}
        </button>
      </div>
    </div>
  )
}
