import { ReactComponent as AccordionArrowRed } from '../../assets/images/AccordionArrowRed.svg'
import { ReactComponent as ArrowWhite } from '../../assets/images/ArrowWhite.svg'
import { BackFormButton } from '../../assets/images'
import './FitBackForm.scss'
import { useEffect, useState } from 'react'
import { TitleLetter } from '../TitleLetter/TitleLetter'
import { useTranslation } from 'react-i18next'

const COMMUNICATION_REQUESTS_SCOPES_OPTIONS = [
  { value: 'web_development', label: 'WEB DEVELOPMENT' },
  { value: 'erp_crm_development', label: 'ERP/CRM DEVELOPMENT' },
  { value: 'design', label: 'DESIGN' },
  { value: 'application_development', label: 'APPLICATION DEVELOPMENT' },
]

const defaultValues = {
  firstName: '',
  surname: '',
  email: '',
  phoneNumber: '',
  content: '',
  scope: 'web_development',
}

export const FitBackForm = ({ formRef, showFitBackForm }) => {
  const [t] = useTranslation('global')
  const [formData, setFormData] = useState(defaultValues)
  const [error, setError] = useState('')

  const errorLabels = {
    emailInvalid: t('form.errorMail'),
    nameEmpty: t('form.errorName'),
    surnameEmpty: t('form.errorSurename'),
    phoneNumberEmpty: t('form.errorPhone'),
    phoneNumberInvalid: t('form.errorInvalidPhone'),
  }

  const TitleLetterChooseSection = {
    labelNumber: '06',
    label: t('form.title.label'),
    text: t('form.title.text'),
    title: t('form.title.title'),
  }

  const [dataErrors, setDataErrors] = useState({
    firstName: '',
    surname: '',
    email: '',
    phoneNumberEmpty: '',
    phoneNumberInvalid: '',
  })

  const [formValid, setFormValid] = useState(false)

  useEffect(() => {
    if (
      Object.values(dataErrors).every((value) => value === '') &&
      formData.scope &&
      formData.firstName &&
      formData.surname &&
      formData.phoneNumber
    ) {
      setFormValid(true)
    }
  }, [dataErrors, formData])

  const isFormValid = () =>
    Object.values(dataErrors).every((value) => value === '') && formValid

  const handleScopeChange = (e) => {
    const { value } = e.target
    setFormData({
      ...formData,
      scope: value,
    })
  }
  const handleChange = (e) => {
    const { value } = e.target
    setFormData({
      ...formData,
      [e.target.name]: value,
    })
    validateField(e.target.name, value)
  }
  const resetForm = () => {
    setFormData(defaultValues)
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'email': {
        validateEmail(value)
        break
      }
      case 'phoneNumber': {
        validatePhoneNumber(value)
        break
      }
      case 'firstName': {
        validateName(value)
        break
      }
      case 'content': {
        break
      }
      default: {
        validateSurname(value)
      }
    }
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (email === '') {
      setDataErrors({
        ...dataErrors,
        email: '',
      })
    } else if (!re.test(String(email).toLowerCase())) {
      setDataErrors({
        ...dataErrors,
        email: errorLabels.emailInvalid,
      })
    } else {
      setDataErrors({
        ...dataErrors,
        email: '',
      })
    }
  }

  const validatePhoneNumber = (phoneNumber) => {
    const re = /^(\+)?(([0-9]){3})?([0-9]){8}(([0-9]){1})?$/
    if (phoneNumber === '') {
      setDataErrors({
        ...dataErrors,
        phoneNumberEmpty: errorLabels.phoneNumberEmpty,
        phoneNumberInvalid: '',
      })
    } else if (!re.test(phoneNumber)) {
      setDataErrors({
        ...dataErrors,
        phoneNumberInvalid: errorLabels.phoneNumberInvalid,
      })
    } else {
      setDataErrors({
        ...dataErrors,
        phoneNumberEmpty: '',
        phoneNumberInvalid: '',
      })
    }
  }

  const validateName = (name) => {
    if (name === '') {
      setDataErrors({
        ...dataErrors,
        firstName: t('form.errorName'),
      })
    } else {
      setDataErrors({
        ...dataErrors,
        firstName: '',
      })
    }
  }

  const validateSurname = (surname) => {
    if (surname === '') {
      setDataErrors({
        ...dataErrors,
        surname: t('form.errorSurename'),
      })
    } else {
      setDataErrors({
        ...dataErrors,
        surname: '',
      })
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    fetch('https://formcarry.com/s/Oqxv4U5vnt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 200) {
          alert('We received your submission, thank you!')
          resetForm()
          showFitBackForm()
        } else if (response.code === 422) {
          // Field validation failed
          setError(response.message)
        } else {
          // other error from formcarry
          setError(response.message)
        }
      })
      .catch((error) => {
        // request related error.
        setError(error.message ? error.message : error)
      })
  }

  return (
    <form className="Form" ref={formRef} onSubmit={handleSubmit}>
      <div className="Form-container">
        <div className="Form-container-topPart">
          <button
            type="button"
            className="Form-closeBtn"
            onClick={showFitBackForm}
          >
            <img src={BackFormButton} alt="" />
          </button>
          <TitleLetter
            title={TitleLetterChooseSection.title}
            label={TitleLetterChooseSection.label}
            labelNumber={TitleLetterChooseSection.labelNumber}
            text={TitleLetterChooseSection.text}
          />
        </div>
        <div className="Form-Button-container">
          {COMMUNICATION_REQUESTS_SCOPES_OPTIONS.map((scope) => (
            <button
              className={
                formData.scope === scope.value
                  ? 'FormButton FormButton_active'
                  : 'FormButton'
              }
              key={scope.value}
              value={scope.value}
              name="scope"
              onClick={handleScopeChange}
              type="button"
            >
              <AccordionArrowRed className="FormArrow" />
              <ArrowWhite className="FormArrowWhite" />
              {scope.label}
            </button>
          ))}
        </div>
        <div className="Form-Part-container">
          <div>
            <input
              className={
                dataErrors.firstName
                  ? 'Form-information Form-information_error'
                  : 'Form-information'
              }
              type="text"
              name="firstName"
              placeholder={
                dataErrors.firstName
                  ? dataErrors.firstName
                  : t('form.placeholder.name')
              }
              value={formData.firstName}
              onChange={handleChange}
            />
            {dataErrors.email && (
              <div className="error">{dataErrors.email}</div>
            )}
            <input
              className="Form-information"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="email*"
            />
          </div>
          <div>
            <input
              className={
                dataErrors.surname
                  ? 'Form-information Form-information_error'
                  : 'Form-information'
              }
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              placeholder={
                dataErrors.surname
                  ? dataErrors.surname
                  : t('form.placeholder.surname')
              }
            />
            {dataErrors.phoneNumberInvalid && (
              <div className="error">{dataErrors.phoneNumberInvalid}</div>
            )}
            <input
              className={
                dataErrors.phoneNumberEmpty
                  ? 'Form-information Form-information_error'
                  : 'Form-information'
              }
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder={
                dataErrors.phoneNumberEmpty
                  ? dataErrors.phoneNumberEmpty
                  : t('form.placeholder.phoneNumber')
              }
            />
          </div>
        </div>
        <div className="Form-Part-describeContainer">
          <textarea
            className="Form-describe"
            name="content"
            value={formData.content}
            onChange={handleChange}
            cols="30"
            rows="10"
            placeholder={t('form.placeholder.describe')}
          ></textarea>
        </div>
        <div className="Form-submitPart">
          <button
            className={
              isFormValid()
                ? 'Form-submitButton'
                : 'Form-submitButton Form-submitButton_disabled'
            }
            type="submit"
            disabled={!isFormValid()}
          >
            {t('form.submitButton')}
          </button>
          <p className="Form-submitText">
            {t('form.submitText')} <br /> {t('form.submitText2')}
          </p>
          {error && <p className="error">{error}</p>}
        </div>
      </div>
    </form>
  )
}
