import './SliderAboutUs.scss'
import { SliderAboutUsContent } from './SliderAboutUsContent'
import { useTranslation } from 'react-i18next'

// swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import { EffectCreative, Pagination } from 'swiper/modules'

export const SliderAboutUs = () => {
  const [t] = useTranslation('global')

  const SliderAboutUsData = [
    {
      themeLbel: '01',
      theme: t('aboutUs.tabs.01'),
      number: '01',
      text: t('aboutUs.tabs.text01'),
    },
    {
      themeLbel: '02',
      theme: t('aboutUs.tabs.02'),
      number: '02',
      text: t('aboutUs.tabs.text02'),
    },
    {
      themeLbel: '03',
      theme: t('aboutUs.tabs.03'),
      number: '03',
      text: t('aboutUs.tabs.text03'),
    },
    {
      themeLbel: '04',
      theme: t('aboutUs.tabs.04'),
      number: '04',
      text: t('aboutUs.tabs.text04'),
    },
  ]

  return (
    <div className="SliderAboutUs">
      <div className="AboutUs-Section-Slider">
        <Swiper
          className="SwiperAboutUs"
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: false,
              translate: ['-120%', 0, -500],
            },
            next: {
              shadow: false,
              translate: ['120%', 0, -500],
            },
          }}
          pagination={true}
          modules={[Pagination, EffectCreative]}
        >
          {SliderAboutUsData.map((data) => (
            <SwiperSlide key={data.number}>
              <SliderAboutUsContent
                text={data.text}
                theme={data.theme}
                themeLabel={data.themeLbel}
                number={data.number}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
