import './ContactsSection.scss'
import { LinkIn, Facebook, Instagram } from '../../../../assets/images'
import { useTranslation } from 'react-i18next'

export const ContactsSection = () => {
  const [t] = useTranslation('global')

  return (
    <section id="05Section" className="ContactsSection">
      <div className="ContactsSection-topPart">
        <h1 className="ContactsSection-topPart--title">
          {t('contacts.title')}
        </h1>
        <div className="ContactsSection-topPart--titleBackground"></div>
      </div>
      <div className="ContactsSection-bottomPart">
        <div className="ContactsSection-bottom-leftPart">
          <div>
            <a
              className="ContactsSection-bottom-leftPart--mail"
              href="mailto:info@sintech.dev"
            >
              info@sintech.dev
            </a>
            <div className="ContactsSection-bottom-leftPart--line"></div>
          </div>
          <div className="adress-container">
            <p className="ContactsSection-bottom-leftPart--adress">
              Moldova, Comrat, Lenina
            </p>
            <a
              className="ContactsSection-bottom-leftPart--tel"
              href="tel:+373 79 559 338"
            >
              +373 79 559 338
            </a>
          </div>
        </div>
        <div className="ContactsSection-bottom-rightPart">
          <a
            className="ContactsSection-bottom-rightPart--links"
            href="https://www.linkedin.com/company/sintech-corp/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkIn} alt="" />
          </a>
          <a
            className="ContactsSection-bottom-rightPart--links"
            href="https://www.instagram.com/sintech.corporation/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="" />
          </a>
          <a
            className="ContactsSection-bottom-rightPart--links"
            href="https://www.facebook.com/sintech.corporation"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="" />
          </a>
        </div>
      </div>
    </section>
  )
}
