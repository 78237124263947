import { useRef } from 'react'
import { Particle } from '../../../../components/Particles/Particle'
import { FitBackForm } from '../../../../components/Form/FitBackForm'
import './MainPageBanner.scss'
import { useTranslation } from 'react-i18next'

export const MainPageBanner = () => {
  const [t] = useTranslation('global')
  const formRef = useRef(null)
  const showFitBackForm = () => {
    const node = formRef.current
    node?.classList.toggle('Form-show')
  }

  return (
    <section className="MainBanner">
      <FitBackForm formRef={formRef} showFitBackForm={showFitBackForm} />
      <div className="NewsMainBanner">
        <div className="particles_f">
          <div className="ParticleMainTitle">
            <h2>
              {t('mainPage.slogan1')} <br />
              {t('mainPage.slogan2')} <br />
              {t('mainPage.slogan3')} <br />
              <span className="ParticleMainTitle-company--Label">
                {t('mainPage.slogan4label')}
              </span>
              <span className="ParticleMainTitle-company">
                {t('mainPage.slogan4')}
              </span>
            </h2>
          </div>
          <div className="particle_button_cont">
            <button
              className="particle_button"
              onClick={showFitBackForm}
              type="button"
            >
              {t('mainPage.formButton')}
            </button>
            <p className="banner-label">{t('mainPage.label')}</p>
          </div>
          <Particle />
        </div>
      </div>
    </section>
  )
}
