import { CollaboratorItem } from '../../../../components/СollaboratorItem/СollaboratorItem'
import { TitleLetter } from '../../../../components/TitleLetter/TitleLetter'
import {
  SintechLogoLetterOurTeam,
  TitleLetterBackgroundOurTeam,
} from '../../../../assets/images'
import { OurTeamData } from './OurTeamData'
import { useTranslation } from 'react-i18next'
// swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-creative'
//
import './OurTeamSection.scss'
//
import { EffectCreative, Navigation, Pagination } from 'swiper/modules'

export const OurTeamSection = () => {
  const [t] = useTranslation('global')

  const TitleLetterOurTeam = {
    labelNumber: '03',
    label: t('ourTeam.label'),
    text: t('ourTeam.text'),
    title: t('ourTeam.title'),
  }

  const CollaboratorItemProps = {
    name: t('ourTeam.slider.nameN'),
    position: t('ourTeam.slider.positionN'),
    description: t('ourTeam.slider.descriptionN'),
  }
  const CollaboratorItemProps1 = {
    name: t('ourTeam.slider.nameV'),
    position: 'Project Manager',
    description: t('ourTeam.slider.descriptionV'),
  }
  const CollaboratorItemProps2 = {
    name: t('ourTeam.slider.nameCV'),
    position: 'Full-Stack  Developer',
    description: t('ourTeam.slider.descriptionCV'),
  }
  const CollaboratorItemProps3 = {
    name: t('ourTeam.slider.nameAG'),
    position: 'Front-End developer',
    description: t('ourTeam.slider.descriptionAG'),
  }
  const CollaboratorItemProps4 = {
    name: t('ourTeam.slider.nameMA'),
    position: 'UI/UX Designer / Graphic Designer',
    description: t('ourTeam.slider.descriptionMA'),
  }
  const CollaboratorItemProps5 = {
    name: t('ourTeam.slider.nameAF'),
    position: 'Front-End developer',
    description: t('ourTeam.slider.descriptionAF'),
  }
  const CollaboratorItemProps6 = {
    name: t('ourTeam.slider.nameID'),
    position: 'Full-Stack Developer',
    description: t('ourTeam.slider.descriptionID'),
  }
  const CollaboratorItemProps7 = {
    name: t('ourTeam.slider.nameBE'),
    position: 'Back-End Developer, DevOps Engineer',
    description: t('ourTeam.slider.descriptionBE'),
  }
  const CollaboratorItemProps8 = {
    name: t('ourTeam.slider.nameRB'),
    position: 'Front-End developer',
    description: t('ourTeam.slider.descriptionRB'),
  }

  return (
    <section id="03Section">
      <div className="OurTeamSection-Main-container">
        <div className="ConnectsSection-topPart">
          <TitleLetter
            title={TitleLetterOurTeam.title}
            label={TitleLetterOurTeam.label}
            labelNumber={TitleLetterOurTeam.labelNumber}
            text={TitleLetterOurTeam.text}
            image={TitleLetterOurTeam.image}
          />
          <div className="LogoLetterContainer">
            <img className="LogoLetter" src={SintechLogoLetterOurTeam} alt="" />
          </div>
          <img
            className="TitleLetterBackground"
            src={TitleLetterBackgroundOurTeam}
            alt=""
          />
        </div>
        <div className="OurTeamSection-container">
          <Swiper
            style={{
              '--swiper-navigation-color': '#920101',
            }}
            grabCursor={true}
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: ['-120%', 0, -500],
              },
              next: {
                shadow: false,
                translate: ['120%', 0, -500],
              },
            }}
            navigation={true}
            pagination={true}
            modules={[EffectCreative, Navigation, Pagination]}
          >
            <SwiperSlide>
              <div className="Slider-container">
                <CollaboratorItem
                  name={CollaboratorItemProps.name}
                  position={CollaboratorItemProps.position}
                  description={CollaboratorItemProps.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps1.name}
                  position={CollaboratorItemProps1.position}
                  description={CollaboratorItemProps1.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps2.name}
                  position={CollaboratorItemProps2.position}
                  description={CollaboratorItemProps2.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps3.name}
                  position={CollaboratorItemProps3.position}
                  description={CollaboratorItemProps3.description}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="Slider-container">
                <CollaboratorItem
                  name={CollaboratorItemProps4.name}
                  position={CollaboratorItemProps4.position}
                  description={CollaboratorItemProps4.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps5.name}
                  position={CollaboratorItemProps5.position}
                  description={CollaboratorItemProps5.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps6.name}
                  position={CollaboratorItemProps6.position}
                  description={CollaboratorItemProps6.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps7.name}
                  position={CollaboratorItemProps7.position}
                  description={CollaboratorItemProps7.description}
                />
                <div className="OurTeamSection-line"></div>
                <CollaboratorItem
                  name={CollaboratorItemProps8.name}
                  position={CollaboratorItemProps8.position}
                  description={CollaboratorItemProps8.description}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="OurTeamSection-container-mobile">
          <Swiper
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: ['-120%', 0, -500],
              },
              next: {
                shadow: false,
                translate: ['120%', 0, -500],
              },
            }}
            pagination={true}
            modules={[EffectCreative, Pagination]}
          >
            {OurTeamData.map((user) => (
              <SwiperSlide key={user.name}>
                <CollaboratorItem
                  name={user.name}
                  position={user.position}
                  description={user.description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

// className="Slider-container-mobile"
