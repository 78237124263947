import { TitleLetter } from '../../../../components/TitleLetter/TitleLetter'
import { SliderAboutUs } from '../../../../components/SliderAboutUs/SliderAboutUs'
import { Tabs } from '../../../../components/Tabs/Tabs'
// import { TabsBackground } from '../../../../assets/images'
import './ChooseSection.scss'
import { TitleLetterBackgroundChooseUs } from '../../../../assets/images'
import { useTranslation } from 'react-i18next'

export const ChooseSection = () => {
  const [t] = useTranslation('global')

  const TitleLetterChooseSection = {
    labelNumber: '04',
    label: t('aboutUs.label'),
    text: t('aboutUs.text'),
    title: t('aboutUs.title'),
  }

  return (
    <div id="04Section" className="ChooseSection-Main-container">
      <div className="ConnectsSection-topPart">
        <TitleLetter
          title={TitleLetterChooseSection.title}
          label={TitleLetterChooseSection.label}
          labelNumber={TitleLetterChooseSection.labelNumber}
          text={TitleLetterChooseSection.text}
        />
        <img
          className="TitleLetterBackground"
          src={TitleLetterBackgroundChooseUs}
          alt=""
        />
      </div>
      <div className="ChooseSection-container">
        <div className="ChooseSection-Desktop">
          <Tabs />
          {/*<img className="TabsBackground" src={TabsBackground} alt="" />*/}
          {/*<div className="TabsBackground-light"></div>*/}
        </div>
      </div>
      <div className="ChooseSection-Desktop-mobile">
        <SliderAboutUs />
      </div>
    </div>
  )
}
