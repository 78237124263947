import { useRef } from 'react'
import { ReactComponent as AccordionArrowRed } from '../../assets/images/AccordionArrowRed.svg'

export const AccordionItem = ({ AccordionContentItem, onClick, isOpen }) => {
  const itemRef = useRef(null)
  return (
    <li className="accordion-item">
      <button
        className={`accordion-header ${isOpen ? 'button-active' : ''}`}
        onClick={() => onClick()}
      >
        <AccordionArrowRed
          className={`accordion-arrow-red ${isOpen ? 'active-red' : ''}`}
        />
        {AccordionContentItem.AccTitle}
      </button>
      <div
        className="accordion-collapse"
        style={
          isOpen ? { height: itemRef.current.scrollHeight } : { height: '0px' }
        }
      >
        <div className="accordion-body" ref={itemRef}>
          <div>
            <ul className="accordion-list">
              <li className="accordion-text">{AccordionContentItem.AccText}</li>
              <li className="accordion-text">
                {AccordionContentItem.AccText1}
              </li>
            </ul>
          </div>
          <div>
            <ul className="accordion-list">
              <li className="accordion-text">
                {AccordionContentItem.AccText2}
              </li>
              {AccordionContentItem.AccText3 && <li className="accordion-text">
                {AccordionContentItem.AccText3}
              </li>}
            </ul>
          </div>
          <div>
            <ul className="accordion-list">
              {AccordionContentItem.AccText5 && <li className="accordion-text">
                {AccordionContentItem.AccText5}
              </li>}
              {AccordionContentItem.AccText4 && <li className="accordion-text">
                {AccordionContentItem.AccText4}
              </li>}
            </ul>
          </div>
        </div>
      </div>
    </li>
  )
}
