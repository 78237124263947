import { TitleLetter } from '../../../../components/TitleLetter/TitleLetter'
import { ProjectCards } from '../../../../components/ProjectCards/ProjectCards'
import {
  LogoLetterPortfolio,
  TitleLetterBackgroundPortfolio,
} from '../../../../assets/images'
import './PortfolioSection.scss'
import { Link } from 'react-router-dom'
import { PortfolioData } from '../../../PortfolioPage/PortfolioData'
import { useTranslation } from 'react-i18next'

export const PortfolioSection = () => {
  const [t] = useTranslation('global')

  const TitleLetterPortfolio = {
    labelNumber: '02',
    label: t('portfolio.label'),
    text: t('portfolio.text'),
    title: t('portfolio.title'),
  }

  return (
    <section id="02Section">
      <div className="PortfolioSection-Main-container">
        <div className="ConnectsSection-topPart">
          <TitleLetter
            title={TitleLetterPortfolio.title}
            label={TitleLetterPortfolio.label}
            labelNumber={TitleLetterPortfolio.labelNumber}
            text={TitleLetterPortfolio.text}
            image={TitleLetterPortfolio.image}
          />
          <div className="LogoLetterContainer">
            <img className="LogoLetter" src={LogoLetterPortfolio} alt="" />
          </div>
          <img
            className="TitleLetterBackground"
            src={TitleLetterBackgroundPortfolio}
            alt=""
          />
        </div>
        <div className="PortfolioSection-container">
          <div className="PortfolioSection-Desktop">
            <div className="PortfolioSection-Desktop-topPart">
              {PortfolioData.map((project) => (
                <Link
                  key={project.id}
                  className="Project-card--link"
                  to={`/project/${project.id}`}
                >
                  <ProjectCards
                    ProjectCardimage={project.imgProject}
                    ProjectCardlabel={project.labelProject}
                    ProjectCardtitle={project.nameProject}
                  />
                </Link>
              ))}
            </div>
            <div className="PortfolioSection-Desktop-line"></div>
            <div className="PortfolioSection-Desktop-bottomPart">
              <Link className="PortfolioSection-Desktop-link" to="/portfolio">
                {t('portfolio.allWorkButton')}{' '}
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.472656"
                    y="0.929199"
                    width="7.64253"
                    height="7.64253"
                    fill="#D9D9D9"
                  />
                  <rect
                    x="10.0273"
                    y="0.929199"
                    width="7.64253"
                    height="7.64253"
                    fill="#D9D9D9"
                  />
                  <rect
                    x="0.472656"
                    y="10.4824"
                    width="7.64253"
                    height="7.64253"
                    fill="#D9D9D9"
                  />
                  <rect
                    x="10.0273"
                    y="10.4824"
                    width="7.64253"
                    height="7.64253"
                    fill="#D9D9D9"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
