import { useState } from 'react'
import { TabsBackground } from '../../assets/images'
import './Tabs.scss'
import { useTranslation } from 'react-i18next'

export const Tabs = () => {
  const [toggleState, setToggleState] = useState(1)
  const [t] = useTranslation('global')
  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <div className="Tabs-Container">
      <div className="Bloc-Tabs">
        <div className="Tabs-Title--Line"></div>
        <div>
          <span className="Tabs-Label">01</span>
          <button
            className={
              toggleState === 1 ? 'Tabs-Title active-Tabs-Title' : 'Tabs-Title'
            }
            onClick={() => toggleTab(1)}
          >
            {t('aboutUs.tabs.01')}
          </button>
        </div>
        <div className="Tabs-Title--Line"></div>
        <div>
          <span className="Tabs-Label">02</span>
          <button
            className={
              toggleState === 2 ? 'Tabs-Title active-Tabs-Title' : 'Tabs-Title'
            }
            onClick={() => toggleTab(2)}
          >
            {t('aboutUs.tabs.02')}
          </button>
        </div>
        <div className="Tabs-Title--Line"></div>
        <div>
          <span className="Tabs-Label">03</span>
          <button
            className={
              toggleState === 3 ? 'Tabs-Title active-Tabs-Title' : 'Tabs-Title'
            }
            onClick={() => toggleTab(3)}
          >
            {t('aboutUs.tabs.03')}
          </button>
        </div>
        <div className="Tabs-Title--Line"></div>
        <div>
          <span className="Tabs-Label">04</span>
          <button
            className={
              toggleState === 4 ? 'Tabs-Title active-Tabs-Title' : 'Tabs-Title'
            }
            onClick={() => toggleTab(4)}
          >
            {t('aboutUs.tabs.04')}
          </button>
        </div>
        <div className="Tabs-Title--Line"></div>
      </div>

      <div className="Tabs-Content">
        <div className={toggleState === 1 ? 'content' : 'content disabled'}>
          <h2>01</h2>
          <p>{t('aboutUs.tabs.text01')}</p>
        </div>
        <div className={toggleState === 2 ? 'content' : 'content disabled'}>
          <h2>02</h2>
          <p>{t('aboutUs.tabs.text02')}</p>
        </div>
        <div className={toggleState === 3 ? 'content' : 'content disabled'}>
          <h2>03</h2>
          <p>{t('aboutUs.tabs.text03')}</p>
        </div>
        <div className={toggleState === 4 ? 'content' : 'content disabled'}>
          <h2>04</h2>
          <p>{t('aboutUs.tabs.text04')}</p>
        </div>
        <img className="TabsBackground" src={TabsBackground} alt="" />
      </div>
    </div>
  )
}
